<template>
  <div>
    <BHeader />
    <div class="lg:pt-32 pt-12  flex flex-col justify-center items-center">
      <h3
        class="lg:text-4xl text-xl font-bold text-center text-text-alternate-1"
      >
        About Us
      </h3>
      <p class="text-sm text-center text-text-alternate-1 mt-3">
        Get to know more about us and what we stand for!
      </p>
      <hr style="color: #d7d7d7" class="mt-8 mx-auto w-10/12" />
      <BPageLoader v-if="isLoading" />
      
      <div v-else
        class="text-sm text-text-alternate-1 py-4 lg:w-10/12 px-6 lg:mt-10"
        v-html="data.content"
      />

    </div>
  </div>
  <BFooterLG class="hidden mb-16 lg:block" />
</template>

<script>
import { watch, onBeforeUnmount, toRefs } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getAboutUs } from '@/services/api';
import BFooterLG from '../components/ui/BFooterLG.vue';
import BHeader from '../components/ui/BHeader.vue';
import BPageLoader from '../components/ui/BPageLoader.vue';

export default {
  name: 'AboutUsModal',
  emits: ['close'],
  props: ['isOpen'],
  setup(props) {
    const [response, fetchPrivacy] = useApi(getAboutUs);
    fetchPrivacy();
    // console.log(response);
    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });
    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });
    return {
      ...toRefs(response),
    };
  },
  components: { BFooterLG, BHeader, BPageLoader },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  img {
    height: 80px;
  }
}
</style>
